<template>
    <div class="app-down">
        <l-header bg-color="#fff"></l-header>
        <div class="down">
            <div class="app-img"><img-box img="app.png" width="389px" height="464px"></img-box></div>
            <div class="app-bar">
                <p class="title">下载APP使用货想车</p>
                <p class="tab">
                    <span :class="{active:tabIndex === 0}" @click="tabIndex=0">货主端APP</span>
                    <span :class="{active:tabIndex === 1}" @click="tabIndex=1">司机端APP</span>
                    <span :class="{active:tabIndex === 2}" @click="tabIndex=2">小程序扫码使用</span></p>
                <div class="app-box">
                    <div class="cargo-app" v-show="tabIndex === 0">
                        <div class="app-down-bar">
                            <div class="qr-code">
                                <img-box img="cargo_qr.png" width="161px" height="161px"></img-box>
                            </div>
                        </div>
                        <p class="tips">扫码下载货主端APP</p>
                        <div class="app-types">
                            <img-box img="ios.png" width="28px" height="28px"></img-box>
                            <span>IOS</span>
                            <img-box img="Android.png" width="28px" height="28px"></img-box>
                            <span>Android</span>
                        </div>
                    </div>
                    <div class="driver-app" v-show="tabIndex === 1">
                        <div class="app-down-bar">
                            <div class="qr-code">
                                <img-box img="driver_qr.png" width="161px" height="161px"></img-box>
                            </div>
                        </div>
                        <p class="tips">扫码下载车主端APP</p>
                        <div class="app-types">
                            <img-box img="ios.png" width="28px" height="28px"></img-box>
                            <span>IOS</span>
                            <img-box img="Android.png" width="28px" height="28px"></img-box>
                            <span>Android</span>
                        </div>
                    </div>
                    <div class="applets" v-show="tabIndex === 2">
                        <div class="app-down-bar"><img-box img="driver_app.png" width="161px" height="161px"></img-box></div>
                        <p class="tips">扫码使用小程序</p>
                    </div>
                </div>
            </div>
        </div>
        <l-footer></l-footer>
    </div>
</template>

<script>
import LHeader from "@/components/Header";
import ImgBox from "@/components/ImgBox";
import LFooter from "@/components/LFooter";

export default {
    name: "appDown",
    components: {
        LHeader, ImgBox, LFooter
    },
    data(){
        return {
            tabIndex:0,
        }
    },
    mounted() {

    },
}
</script>

<style scoped lang="scss">
.app-down{
    background: #F5F6F8;
}
.down{
    padding: 131px 0 206px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .app-img{
        margin-right: 180px;
    }
    .title{
        font-size: 40px;
        color: #333333;
        margin-bottom: 37px;
    }
    .tab{
        margin-bottom: 37px;
        span{
            display: inline-block;
            width: 176px;
            height: 54px;
            line-height: 54px;
            font-size: 22px;
            color: #9E9E9E;
            text-align: center;
            border: 1px solid #D4D4D4;
            cursor: pointer;
            &.active{
                background: #F9E4E6;
                border: 1px solid #F9574F;
                color: #F9574F;
                &:nth-child(2){
                    border-left: 1px solid #F9574F;
                    border-right: 1px solid #F9574F;
                }
            }
            &:nth-child(1){
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }
            &:nth-child(2){
                border-left: none;
                border-right: none;
            }
            &:nth-child(3){
                border-radius: 0 6px 6px 0;
            }
        }
    }
    .app-down-bar{
        padding: 15px;
        background: #fff;
        display: inline-block;
        margin-bottom: 50px;
    }
    .tips{
        font-size: 18px;
        color: #333;
        margin-bottom: 25px;
    }
    .app-types{
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
            font-size: 16px;
            color: #B0B3B8;
            padding: 0 36px 0 6px;
        }
    }
}
</style>